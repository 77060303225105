// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.user.TeamscaleUserPerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {!$avatarSource.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $avatarSource = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $avatarSource$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.size);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} username
 * @param {null|string|undefined=} size
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $avatarSource$ = function($$areYouAnInternalCaller, $ijData, username, size) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSource']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSource']({username: username, size: size}, $ijData);
  }
  soy.assertParamType(size == null || typeof size === 'string', 'size', size, '@param', 'null|string|undefined');
  return 'api/avatars/' + (username ? soy.$$escapeUri(username) : 'unknown') + '/image?size=' + soy.$$escapeUri(size);
};
/**
 * @typedef {{
 *  username: ?,
 *  size?: (null|string|undefined),
 * }}
 */
$avatarSource.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $avatarSource.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSource';
}


/**
 * @param {?$avatarSizeTranslation.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 * @private
 */
const $avatarSizeTranslation = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $avatarSizeTranslation$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.sizeName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} sizeName
 * @return {string}
 * @private
 * @suppress {checkTypes}
 */
const $avatarSizeTranslation$ = function($$areYouAnInternalCaller, $ijData, sizeName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSizeTranslation']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSizeTranslation']({sizeName: sizeName}, $ijData);
  }
  soy.assertParamType(sizeName == null || typeof sizeName === 'string', 'sizeName', sizeName, '@param', 'null|string|undefined');
  let $output = '';
  const $tmp = sizeName;
  switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
    case 'tiny':
      $output += '50';
      break;
    case 'small':
      $output += '100';
      break;
    case 'medium':
      $output += '200';
      break;
    case 'large':
      $output += '300';
      break;
    default:
      $output += '200';
  }
  return $output;
};
/**
 * @typedef {{
 *  sizeName?: (null|string|undefined),
 * }}
 */
$avatarSizeTranslation.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $avatarSizeTranslation.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSizeTranslation';
}


/**
 * @param {!$roundAvatar.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $roundAvatar = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $roundAvatar$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.user, opt_data.size, opt_data.hideUserName, opt_data.prefixForTooltip, opt_data.dontLinkToUser);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} username
 * @param {?=} user
 * @param {null|string|undefined=} size
 * @param {boolean|null|undefined=} hideUserName
 * @param {null|string|undefined=} prefixForTooltip
 * @param {boolean|null|undefined=} dontLinkToUser
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $roundAvatar$ = function($$areYouAnInternalCaller, $ijData, username, user, size, hideUserName, prefixForTooltip, dontLinkToUser) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar']({username: username, user: user, size: size, hideUserName: hideUserName, prefixForTooltip: prefixForTooltip, dontLinkToUser: dontLinkToUser}, $ijData);
  }
  soy.assertParamType(typeof username === 'string', 'username', username, '@param', 'string');
  soy.assertParamType(size == null || typeof size === 'string', 'size', size, '@param', 'null|string|undefined');
  soy.assertParamType(hideUserName == null || typeof hideUserName === 'boolean', 'hideUserName', hideUserName, '@param', 'boolean|null|undefined');
  soy.assertParamType(prefixForTooltip == null || typeof prefixForTooltip === 'string', 'prefixForTooltip', prefixForTooltip, '@param', 'null|string|undefined');
  soy.assertParamType(dontLinkToUser == null || typeof dontLinkToUser === 'boolean', 'dontLinkToUser', dontLinkToUser, '@param', 'boolean|null|undefined');
  let $output = '';
  const canLinkToUser__soy22175 = soy.$$coerceToBoolean(username) && soy.$$coerceToBoolean(user) && !soy.$$equals(dontLinkToUser, true);
  let $tmp;
  if (soy.$$coerceToBoolean(user) && (!soy.$$equals(user.firstName, '') || !soy.$$equals(user.lastName, ''))) {
    $tmp = soy.$$escapeHtml(user.firstName) + ' ' + soy.$$escapeHtml(user.lastName);
  } else if (soy.$$coerceToBoolean(username) && username != '') {
    $tmp = ' ' + soy.$$escapeHtml(username);
  } else {
    $tmp = ' <i' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:71"' : '') + '>Unknown user</i>';
  }
  let visibleUserNameHtml__soy22176 = '' + $tmp;
  const visibleUserNameHtml__wrapped22176 = soy.VERY_UNSAFE.$$ordainSanitizedHtmlForInternalBlocks(visibleUserNameHtml__soy22176);
  let $tmp$$1;
  if (user) {
    $tmp$$1 = '' + user.firstName + ' ' + '' + user.lastName;
  } else if (soy.$$coerceToBoolean(username) && username != '') {
    $tmp$$1 = ' ' + '' + username;
  } else {
    $tmp$$1 = ' Unknown user';
  }
  let visibleUserNameString__soy22192 = '' + $tmp$$1;
  $output += (canLinkToUser__soy22175 ? '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.showUser$(soy.$$internalCallMarkerDoNotUse, $ijData, user.username))) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:81"' : '') + '>' : '') + '<img class="ui avatar image ' + (size ? soy.$$escapeHtmlAttribute(size) : '') + '"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, (prefixForTooltip ? '' + prefixForTooltip : '') + ' ' + '' + visibleUserNameString__soy22192)) + ' src="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeMediaUri($avatarSource$(soy.$$internalCallMarkerDoNotUse, $ijData, user ? user.username : username, size != null ? size : 'tiny'))) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:85"' : '') + '>' + (!hideUserName ? '<span' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:95"' : '') + '> ' + visibleUserNameHtml__wrapped22176 + '</span>' : '') + (canLinkToUser__soy22175 ? '</a>' : '');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $roundAvatar as roundAvatar };
export { $roundAvatar$ as roundAvatar$ };
/**
 * @typedef {{
 *  username: string,
 *  user?: (?|undefined),
 *  size?: (null|string|undefined),
 *  hideUserName?: (boolean|null|undefined),
 *  prefixForTooltip?: (null|string|undefined),
 *  dontLinkToUser?: (boolean|null|undefined),
 * }}
 */
$roundAvatar.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $roundAvatar.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar';
}
